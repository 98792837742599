<template>
  <div id="ComputerHeader">
  <!-- Header -->
  <v-app-bar app color="rgb(25, 79, 115, 0.1)" absolute dense elevation="0">
    <v-spacer></v-spacer>
    <alerts-center></alerts-center>
    <v-menu left bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon large>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-if="this.$store.state.authStore.isUserLoggedIn" class="header-nav-custom mt-4" to="/accountDetails/me">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mon compte</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('logout')">
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
  </div>
</template>

<script>
import AlertsCenter from '@/components/AlertsCenter'
export default {
  name: "ComputerHeader",
  components: {
    AlertsCenter
  }
}
</script>