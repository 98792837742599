// frontend\src\store\slopesStore.js
import axiosInstance from '@/plugins/connectionBuilder.js'

export default {
    state: {
        slopesMetaData: [],
        dateSlopes: null,
        isProcessing: false,
        isUpdating: false,
        slopesIsLoading: false,
        listDownloadsIsLoading: false,
        listDownloads: [],
        pdfFile: null
    },
    mutations: {
        setSlopesMetaData(state, slopesMetaData) {
            for(let i = 0; i < slopesMetaData.length; i++){
                if (slopesMetaData[i].openingTime == null){
                    slopesMetaData[i].openingTime = slopesMetaData[i].openingTimeDef;
                }
                if (slopesMetaData[i].closingTime === null){
                    slopesMetaData[i].closingTime = slopesMetaData[i].closingTimeDef;
                }
                slopesMetaData[i].openingTime = slopesMetaData[i].openingTime.slice(0,5);
                slopesMetaData[i].closingTime = slopesMetaData[i].closingTime.slice(0,5);
                slopesMetaData[i].commentChanged = false;
                slopesMetaData[i].closingTimeChanged = false;
                slopesMetaData[i].openingTimeChanged = false;

                slopesMetaData[i]['area_value'] = slopesMetaData[i]['area']
                let sectorValue = slopesMetaData[i]['area']
                const SECTORS = ["St Sorlin", "SJA", "Corbier"]
                let sectors = []
                for(let index = 0; index < SECTORS.length; index++) {
                    if((parseInt(sectorValue, 10) & (1 << index)) > 0){
                    sectors.push(SECTORS[SECTORS.length - 1 - index])
                    }          
                }
                slopesMetaData[i]['area'] = sectors.join('/')
            }
            state.slopesMetaData = slopesMetaData;
        },
        setSlopesDate(state, dateSlopes){
            state.dateSlopes = dateSlopes;
        },
        setIsProcessing(state, isProcessing) {
            state.isProcessing = isProcessing
        },
        setIsUpdating(state, isUpdating) {
            state.isUpdating = isUpdating
        },
        setSlopesIsLoading(state, slopesIsLoading) {
            state.slopesIsLoading = slopesIsLoading
        },
        setListDownloads(state, listDownloads) {
            state.listDownloads = listDownloads;
        },
        setPdfFile(state, pdfFile) {
            state.pdfFile = pdfFile;
        },
        setListDownloadsIsLoading(state, listDownloadsIsLoading) {
            state.listDownloadsIsLoading = listDownloadsIsLoading
        },
    },
    actions: {
        updateSlopesMetaData({commit, dispatch}, date) {
            commit('setSlopesIsLoading', true);
            return axiosInstance.post('slopes/getSlopesUsageData', {'date':date})
                .then(function (response) {
                    commit('setSlopesIsLoading', false);
                    commit('setSlopesMetaData', response.data.message);
                    commit('setSlopesDate', date)
                })
                .catch(function (error) {
                    commit('setSlopesIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
            
        },
        addSlope({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('slopes/addSlope', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateSlopesMetaData', payload.date);
                    dispatch("addNotification", {message: "Piste créée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        //Add data in the table of slopeUsage
        actionModifySlopeUsage({commit, dispatch}, payload){
            commit('setIsProcessing', true);
            axiosInstance.post('slopes/addSlopeUsage', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    if (Array.isArray(payload)){
                        dispatch('updateSlopesMetaData', payload[0].date);
                    } else {
                        dispatch('updateSlopesMetaData', payload.date);
                    }
                    
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        updateListDownloads({dispatch, commit}){
            commit('setListDownloadsIsLoading', true);
            axiosInstance.post('slopes/getlistDownloads')
                .then(function (response) {
                    commit('setListDownloadsIsLoading', false);
                    commit('setListDownloads', response.data.message);
                })
                .catch(function (error) {
                    commit('setListDownloadsIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        downloadPdf({dispatch}, payload){
            axiosInstance.post("slopes/downloadPdf",
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                responseType: "blob"
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const objectUrl = window.URL.createObjectURL(blob);
                var link = document.createElement("a");
                link.download = payload.date.slice(6,10)+"-"+payload.date.slice(3,5)+"-"+payload.date.slice(0,2)+'ouvertures.pdf';
                link.href = objectUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((error) => { 
                if (error.response && error.response.data.message) {
                    dispatch("addNotification", {message: error.response.data.message, type: "error"});
                } else {
                    dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                }
             })
        },
        signPdf({dispatch, commit}, payload){
            commit('setIsUpdating', true);
            axiosInstance.post('slopes/signPdf', payload)
                .then(function () {
                    commit('setIsUpdating', false);
                    dispatch("addNotification", {message: "Registre signé avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsUpdating', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        deleteSlope({commit,dispatch}, payload){
            commit('setIsUpdating', true);
            axiosInstance.post('slopes/deleteSlope', {'slopeId' : payload.slopeId})
                .then(function () {
                    commit('setIsUpdating', false);
                    dispatch('updateSlopesMetaData', payload.currentDateDisplayed);
                    dispatch("addNotification", {message: "Piste supprimée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsUpdating', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        modifySlope({commit,dispatch}, payload){
            commit('setIsUpdating', true);
            axiosInstance.post('slopes/modifySlope', payload)
                .then(function () {
                    commit('setIsUpdating', false);
                    dispatch('updateSlopesMetaData', payload.date);
                    dispatch("addNotification", {message: "Piste modifiée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsUpdating', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
    }
};