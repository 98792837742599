//firebase.js

import { initializeApp } from 'firebase/app'
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAvAeLhwra9MAgu3PjwNpbUbNDGIbOYYKg",
    authDomain: "pisteurs-corbier.firebaseapp.com",
    projectId: "pisteurs-corbier",
    storageBucket: "pisteurs-corbier.appspot.com",
    messagingSenderId: "199656884447",
    appId: "1:199656884447:web:3b1d79a0d8301dea36816a",
    measurementId: "G-1ZSPHX44Z9"
  };

let messaging = null
try {
  const app = initializeApp(firebaseConfig);
  messaging = getMessaging(app)
} catch {
  console.log("Firebase notifications unsupported")
}
export default messaging;