<template>
  <v-app>
    <!-- Computer -->
    <ComputerHeader
    v-if="$store.state.authStore.isUserLoggedIn && $vuetify.breakpoint.mdAndUp && !isMobile"
    @logout="logout"
    />
    <ComputerDrawer
    v-if="$vuetify.breakpoint.mdAndUp && !isMobile"
    :nav="navigationRoutesPerso"
    :messageConnected="isConnectedData"
    :pageTitle="currentRouteName"
    />

    <!-- MOBILE  -->
    <MobileHeader
    v-if="!$vuetify.breakpoint.mdAndUp || isMobile"
    :nav="navigationRoutesPerso"
    :messageConnected="isConnectedData"
    :pageTitle="currentRouteName"
    @logout="logout"
    />
    <v-main>
      <router-view/>
      <NotificationsCenter/>
    </v-main>
  </v-app>
</template>

<script>
import {getToken} from 'firebase/messaging';
import NotificationsCenter from './modules/notification/NotificationsCenter.vue'
import ComputerDrawer from './components/header/computer/ComputerDrawer'
import ComputerHeader from './components/header/computer/ComputerHeader'
import MobileHeader from './components/header/mobile/MobileHeader'

export default {
  data: () => ({
    SECTORS: ["St Sorlin", "SJA", "Corbier"],
    selectedItem: 1,
    drawer: false,
    navigationRoutes: [
      {groupTitle: null, links: [
        {text: "Carte globale", url: "/map", icon: "mdi-map", whenNotConnected: false, whenConnected: true, requiresAdminOrRegul: false, requiresRM: false, requiresResponsableRMOrRegul: false, requiresPistes: false},
      ]},
      {groupTitle: "DigiPistes", links: [
        {text: "Registre pistes", url: "/dataSlopes", icon:"mdi-view-dashboard", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: false, requiresRM: false, requiresResponsableRMOrRegul: false, requiresPistes: true},
        {text: "Historique", url: "/dataDownloads", icon: "mdi-clock", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: false, requiresRM: false, requiresResponsableRMOrRegul: false, requiresPistes: true},
      ]},
      {groupTitle: "DigiRMs", links: [
        {text: "Registre RM", url: "/liftRecord", icon: "mdi-gondola", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: false, requiresRM: true, requiresResponsableRMOrRegul: false},
        {text: "Central RMs", url: "/liftCentral", icon: "mdi-bulldozer", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: false, requiresRM: true, requiresResponsableRMOrRegul: false},
        {text: "Configuration essais RM", url: "/testsConfiguration", icon: "mdi-cog", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: false, requiresRM: false, requiresResponsableRMOrRegul: true},
      ]},
      {groupTitle: "Comptes", links: [
        {text: "Gestion comptes", url: "/manageAccounts", icon: "mdi-account-box-multiple", whenNotConnected: false, whenConnected: false, requiresAdminOrRegul: true, requiresRM: false, requiresResponsableRMOrRegul: false},
        {text: "Se connecter", url: "/login", icon: "mdi-login", whenNotConnected: true, whenConnected: false, requiresAdminOrRegul: false, requiresRM: false, requiresResponsableRMOrRegul: false},
      ]},
    ],
  }),
  components: {ComputerHeader, ComputerDrawer, MobileHeader, NotificationsCenter},
  computed: {
    currentRouteName() {
      if (this.$route.path == '/dataSlopes'){
        return this.$route.name + ' (' + this.sectorValueToName(this.$store.state.authStore.dataUser.sector) + ')'
      }
      return this.$route.name;
    },
    navigationRoutesPerso() {
      let routesFinal = []
      this.navigationRoutes.forEach( group => {
        let routesTemp = []
        group.links.forEach(item => {
          if(
            (this.$store.state.authStore.isUserLoggedIn && item.whenConnected) ||
            (!this.$store.state.authStore.isUserLoggedIn && item.whenNotConnected) ||
            (this.$store.state.authStore.isUserAdminOrRegul && item.requiresAdminOrRegul) ||
            (this.$store.state.authStore.isResponsableRMOrRegul && item.requiresResponsableRMOrRegul) ||
            (this.$store.state.authStore.isRM && item.requiresRM) ||
            (this.$store.state.authStore.isPistes && item.requiresPistes)
          ) {
            routesTemp.push(item)
          }
        })
        if(routesTemp.length != 0){
          group['linksFiltered'] = routesTemp
          routesFinal.push(group)
        } 
      })
      return routesFinal
    },
    isConnectedData() {
      if (this.$store.state.authStore.isUserLoggedIn) {
        return "Bonjour " + this.$store.state.authStore.dataUser['firstname'];
      } else {
        return "Veuillez vous connecter";
      }
    },
    isMobile() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      }
      return false
    },
  },
  methods: {
    sectorValueToName(sectorValue) {
      let sectors = []
      for(let index = 0; index < this.SECTORS.length; index++) {
        if((parseInt(sectorValue, 10) & (1 << index)) > 0){
          sectors.push(this.SECTORS[this.SECTORS.length - 1 - index])
        }          
      }
      const sectorName = sectors.join('/')
      return sectorName
    },
    logout() {
      this.$store.dispatch("logout", {'controllerReference': this})
          .then(function (controller) {
            controller.$router.push('/login');
          });
      return
    },
  },
  mounted() {
    if (localStorage.loginToken){
      this.$store.dispatch("authenticateUserAndSetToken")
    }
    getToken(this.$messaging, {vapidKey: 'BG-F5hzLjKSdSdOqKB6cTU7AqU6suVI_L92HQneISNa9cxtFz5BqR1Q5KJyFy_5lDZWz6qDa2e3KWQwoKJS-zDU'}).then((currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        localStorage.firebaseToken = currentToken;
        console.log('Permission granted')
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    }).catch((err) => {
      console.log('Permission not granted.', err);
      // ...
    });
  },
  created() {
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.$store.state.authStore.deferredPrompt = e;
    });
    window.addEventListener("appinstalled", () => {
      this.$store.state.authStore.deferredPrompt = null;
    });
  },
}
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#notificationCenter {
  position: fixed;
  right: 10px;
  bottom: 0;
  margin-bottom: 10px;
  z-index: 10000;
}

.v-alert.notificationAlert {
  margin-bottom: 4%;
}
</style>