<template>
  <v-app-bar app absolute color="#194f73" dense dark style="position:fixed">
    <!-- MENU BURGER -->
    <v-menu offset-y class="mx-auto" max-width="300" max-height="88%" tile>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </template>

      <v-list shaped>
        <v-subheader>{{ messageConnected }}</v-subheader>
        <v-list-item-group
          v-for="(group, index) in nav" :key="index"
          mandatory
          color="indigo">

          <span v-if="group.groupTitle">
            <v-divider></v-divider>
            <v-subheader class="mx-2" style="height: 25px;">{{ group.groupTitle }}</v-subheader>
          </span>
          <v-list-item v-for="item in group.linksFiltered" :key="item.url" :to="item.url">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
    <img class="py-1" src="@/assets/digiski_blanc_50_50.png" height="50" alt=""/>
    <v-divider
      class="mx-1"
      inset
      vertical></v-divider>
      <v-spacer></v-spacer>
    <v-toolbar-title style="white-space: normal; font-size: 1.8vh;">{{ pageTitle }}</v-toolbar-title>

    <v-spacer></v-spacer>
    <v-divider
      class="mx-1"
      inset
      vertical></v-divider>
    <v-spacer></v-spacer>
    <v-toolbar-title style="white-space: normal; font-size: 1.8vh;">{{ messageConnected }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- MENU DROIT -->
    <v-menu left bottom offset-y v-if="$store.state.authStore.isUserLoggedIn">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item v-if="this.$store.state.authStore.isUserLoggedIn" class="header-nav-custom mt-4" to="/accountDetails/me">
          <v-list-item-icon>
            <v-icon>mdi-account-box</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Mon compte</v-list-item-title>
        </v-list-item>
        <v-list-item @click="$emit('logout')">
          <v-list-item-title>Déconnexion</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    nav: Array,
    messageConnected: String,
    pageTitle: String,
  },
}
</script>