// frontend\src\plugins\connectionBuilder.js
// Author: Author : Andre Baldo (http://github.com/andrebaldo/) 
import axios from "axios";
import router from '../router';
import store from '../store'

const apiUrlProduction = 'https://digiski.sybelles.ski/api'
const apiUrl = process.env.NODE_ENV && process.env.NODE_ENV == 'development' ? 'http://127.0.0.1:5000' : apiUrlProduction

const axiosInstance = axios.create({
    baseURL: apiUrl,
});

if (localStorage.loginToken) {
    axiosInstance.defaults.headers.common['Authorization'] = localStorage.loginToken;
}

// Add a 401 and 403 response interceptor
axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response) {
        if (401 === error.response.status) {
            router.push('/login');
            store.dispatch("addNotification", {message: "Veuillez vous reconnecter", type: "error", time: 6});
        }
        else if (403 === error.response.status) {
            store.dispatch("addNotification", {message: "Vous n'avez pas les droits pour effectuer cette action", type: "error", time: 6});
        }
    } else if (error.request) {
        store.dispatch("addNotification", {message: "Veuillez vérifier votre connexion internet", type: "error", time: 6});
    }    
    return Promise.reject(error);
});

export default axiosInstance;
