<template>
    <v-alert
    v-model="isDisplayed"
    class="notificationAlert"
    transition="slide-x-transition"
    :type="type"
    :icon="iconProcessed"
    dismissible
    dense
    >
    {{message}}
    </v-alert>
</template>

<script>
    export default {
        name: "AlertNotification",
        props: {
            icon: {
                type: String
            },
            message: {
                type: String,
                default: "🚧 You miss something ..."
            },
            type: {
                type: String,
                default: "info"
            },
            time: {
                type: Number,
                default: 3
            }
        },
        data(){
            return {
                isDisplayed: true
            }
        },
        computed: {
            iconProcessed(){
                if(this.icon) {
                    return this.icon
                }
                switch(this.type){
                    case "warning", "error":
                        return "mdi-alert-circle"
                    case "success":
                        return "mdi-check-circle"
                    default:
                        return "mdi-information"
                }
            }
        },
        methods: {
            hide_alert: function () {
            window.setTimeout(() => {
                //Launch transition
                this.isDisplayed = false;
                window.setTimeout(() => {
                    //Completely erase the notif
                    this.$store.dispatch("removeNotification", this.$vnode.key);
                }, 3000)
            }, this.time*1000)
            }
        },
        mounted() {
            this.hide_alert();
        }
    }
</script>