// frontend\src\store\index.js
// Author: Author : Andre Baldo (http://github.com/andrebaldo/) 
import Vue from 'vue'
import Vuex from 'vuex'
import authStore from '@/modules/authentication/authStore'
import slopesStore from '@/store/slopesStore'
import liftsStore from '@/store/liftsStore'
import notifStore from '@/modules/notification/notifStore'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    authStore,
    slopesStore,
    liftsStore,
    notifStore
  }
})