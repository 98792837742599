<template>
    <v-menu left bottom offset-y max-width="500px">
        <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
            <v-badge
            color="error"
            bottom
            overlap
            :content="new_alerts"
            :value="new_alerts"
            >
                <v-icon large>mdi-alert-box</v-icon>
            </v-badge>
        </v-btn>
        </template>
        <v-list dark style="max-height: 500px" class="overflow-y-auto" color="#194f73" v-if="alerts.length > 0">
            <v-subheader>
                <span class="font-weight-bold" style="color:white; font-size:large;">Alertes</span>
                <v-spacer></v-spacer>
                <span class="font-weight-bold" style="color:white; font-size:large;">{{ alerts.length }} alertes | {{ new_alerts }} non lues</span>
            </v-subheader>
            <v-divider></v-divider>
            <template v-for="(alert,index) in alerts">
                <v-divider
                v-if="index > 0"
                inset
                :key="index"
                ></v-divider>
                <v-list-item :key="'a'+index">
                    <v-list-item-avatar>
                        <v-badge 
                        color="error"
                        dot
                        bottom
                        overlap
                        :value="alert.seen == false">
                            <v-icon>{{ alert.icon }}</v-icon>
                        </v-badge>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span :class="!(alert.seen) ? 'font-weight-bold' : ''">{{ alert.name }}</span>
                            <span class="font-italic" v-if="alert.seen"> - {{ alert.date.toLocaleString().split(' ')[0] }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            {{ alert.description }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <dialog-infos-task :task="task_from_id(alert.task_id)" v-if="alert.task_id"></dialog-infos-task>
                    </v-list-item-action>
                </v-list-item>
            </template>
        </v-list>
        <v-list dark color="#194f73" v-else>
        <v-list-item-content>
            <v-list-item-title>Aucune alerte</v-list-item-title>
        </v-list-item-content>
        </v-list>
    </v-menu>
</template>

<script>
import DialogInfosTask from '@/components/DialogInfosTask'
  export default {
    name: "AlertsCenter",
    components: {
      DialogInfosTask
    },
    data () {
      return {
        alerts: [
          {icon: "mdi-cog-outline", name:"Alerte de test - Rippage - Mont Corbier", description: "Alerte de test", date: new Date(), seen: true},
        ]
      }
    },
    computed: {
        tasks(){
            let tasks = this.$store.state.liftsStore.listTasksMaintenance
            return tasks
        },
        new_alerts(){
            return this.alerts.filter(alert => alert.seen == false).length
        }
    },
    methods: {
        task_from_id(id){
            let task_infos = this.tasks.find(task => task.task_id == id)
            console.log(task_infos)
            return task_infos
        },
    },
    mounted(){
      this.$store.dispatch('updateListTasksMaintenance')
    }
  }
</script>