// frontend\src\store\liftsStore.js
import axiosInstance from '@/plugins/connectionBuilder.js'

export default {
    state: {
        isProcessing: false,
        liftRecordIsLoading: false,
        liftListRecordIsLoading: false,
        liftsListIsLoading: false,
        breakdownsListIsLoading: false,
        commentsRecordsListIsLoading: false,
        tasksRoutineListIsLoading: false,
        tasksMaintenanceListIsLoading: false,
        listTaskMaintenanceHistoryIsLoading: false,
        listAllTestsIsLoading: false,
        listRecordTestsIsLoading: false,
        liftsStatesIsLoading : false,
        listDataStatsRecordsIsLoading: false,
        liftRecordProps: {'date': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)},
        liftRecord: {},
        listLiftRecords: [],
        liftsList: [],
        dateLifts: new Date(),
        selectedLiftCentral: {lift_id: null},
        listRMPersons: [],
        listBreakdowns: [],
        listCommentsRecords: [],
        listTasksRoutine: [],
        listTasksMaintenance: [],
        listTaskMaintenanceHistory: [],
        listAllTests: [],
        listRecordTests: [],
        liftsStates: {},
        listDataStatsRecords: [],
    },
    mutations: {
        setIsProcessing(state, isProcessing) {
            state.isProcessing = isProcessing
        },
        setLiftRecordIsLoading(state, liftRecordIsLoading) {
            state.liftRecordIsLoading = liftRecordIsLoading
        },
        setLiftListRecordIsLoading(state, liftListRecordIsLoading) {
            state.liftListRecordIsLoading = liftListRecordIsLoading
        },
        setLiftsListIsLoading(state, liftsListIsLoading) {
            state.liftsListIsLoading = liftsListIsLoading
        },
        setBreakdownsListIsLoading(state, breakdownsListIsLoading) {
            state.breakdownsListIsLoading = breakdownsListIsLoading
        },
        setCommentsRecordsListIsLoading(state, commentsRecordsListIsLoading) {
            state.commentsRecordsListIsLoading = commentsRecordsListIsLoading
        },
        setTasksRoutineListIsLoading(state, tasksRoutineListIsLoading) {
            state.tasksRoutineListIsLoading = tasksRoutineListIsLoading
        },
        setTasksMaintenanceListIsLoading(state, tasksMaintenanceListIsLoading) {
            state.tasksMaintenanceListIsLoading = tasksMaintenanceListIsLoading
        },
        setListTaskMaintenanceHistoryIsLoading(state, listTaskMaintenanceHistoryIsLoading) {
            state.listTaskMaintenanceHistoryIsLoading = listTaskMaintenanceHistoryIsLoading
        },
        setListAllTestsIsLoading(state, listAllTestsIsLoading){
            state.listAllTestsIsLoading = listAllTestsIsLoading
        },
        setListRecordTestsIsLoading(state, listRecordTestsIsLoading){
            state.listRecordTestsIsLoading = listRecordTestsIsLoading
        },
        setLiftsStatesIsLoading(state, liftsStatesIsLoading){
            state.liftsStatesIsLoading = liftsStatesIsLoading
        },
        setListDataStatsRecordsIsLoading(state, listDataStatsRecordsIsLoading){
            state.listDataStatsRecordsIsLoading = listDataStatsRecordsIsLoading
        },
        setLiftIdRecordProps(state, lift_id){
            state.liftRecordProps['lift_id'] = lift_id
        },
        setLiftDateRecordProps(state, date){
            state.liftRecordProps['date'] = date
        },
        setLiftRecord(state, liftRecord) {
            state.liftRecord = liftRecord
        },
        setLiftRecordElement(state, dictUpdating){
            for (const property in dictUpdating){
                state.liftRecord[property] = dictUpdating[property]
            }
        },
        setListLiftRecords(state, listLiftRecords) {
            state.listLiftRecords = listLiftRecords
        },
        setLiftsList(state, liftsList) {
            state.liftsList = liftsList
        },
        setLiftsDates(state, dateLifts){
            state.dateLifts = dateLifts;
        },
        setSelectedLiftCentral(state, lift){
            state.selectedLiftCentral = lift
        },
        setListRMPersons(state, listRMPersons){
            state.listRMPersons = listRMPersons
        },
        setListBreakdowns(state, listBreakdowns) {
            state.listBreakdowns = listBreakdowns
        },
        setListCommentsRecords(state, listCommentsRecords){
            state.listCommentsRecords = listCommentsRecords
        },
        setListTasksRoutine(state, listTasksRoutine){
            state.listTasksRoutine = listTasksRoutine
        },
        setListTasksRoutineElement(state, dictUpdating){
            for (const property in dictUpdating){
                if(property != 'index'){
                    state.listTasksRoutine[dictUpdating['index']][property] = dictUpdating[property]
                }
            }
        },
        setListTasksMaintenance(state, listTasksMaintenance){
            state.listTasksMaintenance = listTasksMaintenance
        },
        setListTaskMaintenanceHistory(state, listTaskMaintenanceHistory){
            state.listTaskMaintenanceHistory = listTaskMaintenanceHistory
        },
        setListAllTests(state, listAllTests){
            state.listAllTests = listAllTests
        },
        setListRecordTests(state, listRecordTests){
            state.listRecordTests = listRecordTests
        },
        setLiftsStates(state, liftsStates){
            state.liftsStates = liftsStates
        },
        setListDataStatsRecords(state, listDataStatsRecords){
            state.listDataStatsRecords = listDataStatsRecords
        },
    },
    actions: {
        getLiftsList({commit, dispatch, state}, date) {
            if(date == null){
                date = new Date()
            }
            commit('setLiftsListIsLoading', true);
            return axiosInstance.post('lifts/getLiftsList', {'date': date})
                .then(function (response) {
                    commit('setLiftsListIsLoading', false);
                    commit('setLiftsList', response.data.message);
                    if(state.selectedLiftCentral.lift_id != null){
                        const arrayData = response.data.message.filter(el => el.lift_id == state.selectedLiftCentral.lift_id)
                        //Doesn't work because on click on deletion button, it selects the deleted lift before deletion...
                        if(arrayData.length != 0){
                            commit('setSelectedLiftCentral', arrayData[0])                            
                        } else {
                            commit('setSelectedLiftCentral', {lift_id: null})
                        }
                    }
                })
                .catch(function (error) {
                    commit('setLiftsListIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        addLift({state, commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addLift', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('getLiftsList', state.dateLifts);
                    dispatch("addNotification", {message: "Remontée créée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        //Add data in the table of liftUsage
        modifyLiftRecord({state, commit, dispatch}, payload){
            commit('setIsProcessing', true);
            const payloadWithInfos = {'data': payload, 'params': state.liftRecordProps}
            axiosInstance.post('lifts/modifyLiftRecord', payloadWithInfos)
                .then(function (response) {
                    commit('setIsProcessing', false);
                    if(response.data.newLiftUsage != null){
                        commit('setLiftRecord', response.data.newLiftUsage);
                    } else {
                        dispatch("addNotification", {message: "Impossible de mettre à jour le registre", type: "error"});
                    }

                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "La modification n'a pas été prise en compte", type: "error"});
                    }
                });
        },
        // Add a value to a test of a record
        modifyTestLiftRecord({commit, dispatch}, payload){
            commit('setIsProcessing', true);
            axiosInstance.post('lifts/modifyTestLiftRecord', payload)
                .then(function (response) {
                    commit('setIsProcessing', false);
                    if(response.data.newLiftUsage != null){
                        commit('setLiftRecord', response.data.newLiftUsage);
                    } else {
                        dispatch("addNotification", {message: "Impossible de mettre à jour le registre", type: "error"});
                    }
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "La modification n'a pas été prise en compte", type: "error"});
                    }
                });
        },
        // Get the record corresponding to a date and a lift
        getLiftRecordStored({commit, dispatch, state}) {
            return new Promise(function (resolve, reject) {
                commit('setLiftRecordIsLoading', true);
                axiosInstance.post('lifts/getLiftRecord', state.liftRecordProps)
                    .then(function (response) {
                        commit('setLiftRecordIsLoading', false);
                        if(response.data.message != null){
                            commit('setLiftRecord', response.data.message);
                        } else {
                            commit('setLiftRecord', {});
                        }
                        resolve()
                    })
                    .catch(function (error) {
                        commit('setLiftRecordIsLoading', false);
                        if (error.response && error.response.data.message) {
                            dispatch("addNotification", {message: error.response.data.message, type: "error"});
                        } else {
                            dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                        }
                        reject()
                    });
            })
        },
        // Get states of lift on a given date
        getLiftsStates({commit, dispatch}, date) {
            commit('setLiftsStatesIsLoading', true);
            return axiosInstance.post('lifts/getLiftsStates', {'date': date})
                .then(function (response) {
                    commit('setLiftsStatesIsLoading', false);
                    commit('setLiftsStates', response.data.message);
                })
                .catch(function (error) {
                    commit('setLiftsStatesIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of records available (dates, liftId for which there is a record)
        updateListLiftRecords({state, dispatch, commit}){
            commit('setLiftListRecordIsLoading', true);
            axiosInstance.post('lifts/updateListLiftRecords', {'lift_id': state.selectedLiftCentral.lift_id})
                .then(function (response) {
                    commit('setLiftListRecordIsLoading', false);
                    commit('setListLiftRecords', response.data.message);
                })
                .catch(function (error) {
                    commit('setLiftListRecordIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        updateListRMPersons({state, commit, dispatch}){
            axiosInstance.post('lifts/updateListRMPersons', {'date': state.liftRecordProps['date']})
            .then(function (response) {
                commit('setListRMPersons', response.data.message);
            })
            .catch(function (error) {
                if (error.response && error.response.data.message) {
                    dispatch("addNotification", {message: error.response.data.message, type: "error"});
                } else {
                    dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                }
            });
        },
        callSetter({commit}, {setterName, value}){
            commit(setterName, value)
        },
        addBreakdown({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addBreakdown', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch("addNotification", {message: "Panne ajoutée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of breakdowns
        updateListBreakdowns({state, dispatch, commit}, payload){
            if (payload == null){
                payload = {'date': null, 'lift_id': state.selectedLiftCentral.lift_id}
            }
            commit('setBreakdownsListIsLoading', true);
            axiosInstance.post('lifts/getBreakdowns', payload)
                .then(function (response) {
                    commit('setBreakdownsListIsLoading', false);
                    commit('setListBreakdowns', response.data.message);
                })
                .catch(function (error) {
                    commit('setBreakdownsListIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of comments
        updateListCommentsRecords({state, dispatch, commit}, payload){
            if (payload == null){
                payload = {'date': null, 'lift_id': state.selectedLiftCentral.lift_id}
            }
            commit('setCommentsRecordsListIsLoading', true);
            axiosInstance.post('lifts/getCommentsRecords', payload)
                .then(function (response) {
                    commit('setCommentsRecordsListIsLoading', false);
                    commit('setListCommentsRecords', response.data.message);
                })
                .catch(function (error) {
                    commit('setCommentsRecordsListIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        addTaskRoutine({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addTaskRoutine', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateListTasksRoutine', {'lift_id': payload.lift_id})
                    dispatch("addNotification", {message: "Tâche routine ajoutée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        deleteTaskRoutine({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/deleteTaskRoutine', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateListTasksRoutine', {'lift_id': payload.lift_id})
                    dispatch("addNotification", {message: "Tâche routine supprimée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        addTaskMaintenance({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addTaskMaintenance', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    if('record_created' in payload && payload['record_created'] != null){
                        dispatch('updateListTasksMaintenance', {record_created: payload['record_created']})
                    } else {
                        dispatch('updateListTasksMaintenance')
                    }
                    dispatch("addNotification", {message: "Tâche maintenance ajoutée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        deleteTaskMaintenance({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/deleteTaskMaintenance', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateListTasksMaintenance', {'lift_id': payload.lift_id})
                    dispatch("addNotification", {message: "Tâche maintenance supprimée avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of routine tasks
        updateListTasksRoutine({dispatch, commit, rootState}, payload){
            commit('setTasksRoutineListIsLoading', true);
            axiosInstance.post('lifts/getTasksRoutine', payload)
                .then(function (response) {
                    commit('setTasksRoutineListIsLoading', false);
                    let listTasksRoutine = response.data.message
                    listTasksRoutine.forEach(el => el['image'] = el['has_image'] ? axiosInstance.defaults.baseURL + "/dataLogged/images/tasks_routine/"+el['task_id']+".jpg/" + rootState.authStore.loginToken+"?timestamp="+Date.now() : null)
                    commit('setListTasksRoutine', listTasksRoutine);
                })
                .catch(function (error) {
                    commit('setTasksRoutineListIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of maintenance tasks
        updateListTasksMaintenance({state, dispatch, commit, rootState}, payload){
                if (payload == null){
                    payload = {'lift_id': state.selectedLiftCentral.lift_id}
                }
                commit('setTasksMaintenanceListIsLoading', true);
                axiosInstance.post('lifts/getTasksMaintenance', payload)
                    .then(function (response) {
                        commit('setTasksMaintenanceListIsLoading', false);
                        let listTasksMaintenance = response.data.message
                        listTasksMaintenance.forEach(el => el['image'] = el['has_image'] ? axiosInstance.defaults.baseURL + "/dataLogged/images/tasks_maintenance/"+el['task_id']+".jpg/"+ rootState.authStore.loginToken + "?timestamp="+Date.now() : null)
                        commit('setListTasksMaintenance', listTasksMaintenance);
                    })
                    .catch(function (error) {
                        commit('setTasksMaintenanceListIsLoading', false);
                        if (error.response && error.response.data.message) {
                            dispatch("addNotification", {message: error.response.data.message, type: "error"});
                        } else {
                            dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                        }
                    });
        },
        addDoneTaskMaintenance({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addDoneTaskMaintenance', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateListTasksMaintenance')
                    dispatch("addNotification", {message: "Tâche effectuée mise à jour avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of the history of the given maintenance task
        updateListTaskMaintenanceHistory({dispatch, commit}, payload){
            commit('setListTaskMaintenanceHistoryIsLoading', true);
            axiosInstance.post('lifts/getTasksMaintenanceHistory', payload)
                .then(function (response) {
                    commit('setListTaskMaintenanceHistoryIsLoading', false);
                    commit('setListTaskMaintenanceHistory', response.data.message);
                })
                .catch(function (error) {
                    commit('setListTaskMaintenanceHistoryIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        downloadPdfRecord({dispatch}, payload){
            axiosInstance.post("lifts/downloadPdfRecord",
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                },
                responseType: "blob"
            }).then((response) => {
                const blob = new Blob([response.data], { type: 'application/pdf' });
                const objectUrl = window.URL.createObjectURL(blob);
                var link = document.createElement("a");
                link.download = payload.date_usage+'registreExploitation'+payload.lift_name+'.pdf';
                link.href = objectUrl;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((error) => {
                if (error.response && error.response.data.message) {
                    dispatch("addNotification", {message: error.response.data.message, type: "error"});
                } else {
                    dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                }
             })
        },
        addNewTest({commit, dispatch}, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('lifts/addNewTest', payload)
                .then(function () {
                    commit('setIsProcessing', false);
                    dispatch('updateListAllTests')
                    dispatch("addNotification", {message: "Essai ajouté avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        deleteTest({dispatch}, payload){
            axiosInstance.post('lifts/deleteTest', payload)
                .then(function () {
                    dispatch('updateListAllTests')
                    dispatch("addNotification", {message: "Essai supprimé avec succès", type: "success"});
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of all tests
        updateListAllTests({dispatch, commit}){
            commit('setListAllTestsIsLoading', true);
            axiosInstance.post('lifts/getTests')
                .then(function (response) {
                    commit('setListAllTestsIsLoading', false);
                    commit('setListAllTests', response.data.message);
                })
                .catch(function (error) {
                    commit('setListAllTestsIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the list of tests in the record
        updateListRecordTests({dispatch, commit}, payload){
            commit('setListRecordTestsIsLoading', true);
            axiosInstance.post('lifts/getTestsOfLift', payload)
                .then(function (response) {
                    commit('setListRecordTestsIsLoading', false);
                    commit('setListRecordTests', response.data.message);
                })
                .catch(function (error) {
                    commit('setListRecordTestsIsLoading', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        deleteLift({state, dispatch}, payload){
            axiosInstance.post('lifts/deleteLift', payload)
                .then(function () {
                    dispatch('getLiftsList', state.dateLifts);
                    dispatch("addNotification", {message: "Appareil supprimé avec succès", type: "success"});
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        // Update the stats data
        updateStatsLifts({state, dispatch, commit}){
            const payload = {'infos': ['people_count_end_day_montee', 'people_count_end_day_descente', 'SUM#breakdown-time'], 'lift_id': state.selectedLiftCentral.lift_id}
            commit('setListDataStatsRecordsIsLoading', true);
            axiosInstance.post('lifts/getInfosRecords', payload)
                .then(function (response) {
                    commit('setListDataStatsRecords', response.data.message);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
            commit('setListDataStatsRecordsIsLoading', false);
        },
    },
    getters: {
        color_state_lift: (state) => (idLift) => {
            if(state.liftsStates[idLift] != null){
                const current_time = state.dateLifts.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', second: '2-digit'})
                const nb_checks_passes = state.liftsStates[idLift].total_empty_tests_ok + state.liftsStates[idLift].total_in_use_tests_ok + state.liftsStates[idLift].total_on_stop_tests_ok
                const nb_checks_totaux = state.liftsStates[idLift].total_empty_tests_needed + state.liftsStates[idLift].total_in_use_tests_needed + state.liftsStates[idLift].total_on_stop_tests_needed
                var percentage = 0
                if (nb_checks_passes > 0){
                    percentage = (nb_checks_totaux/nb_checks_passes)*100
                }
                if(current_time > state.liftsStates[idLift].public_closing_time){
                  return {color: "red", percentage_checks: percentage}
                }
                else if(current_time > state.liftsStates[idLift].public_opening_time){
                  return {color: "green", percentage_checks: percentage}
                }
                else if (nb_checks_passes === nb_checks_totaux){
                  return {color: "blue", percentage_checks: percentage}
                }
                else if (nb_checks_passes > 0){
                  return {color: "orange", percentage_checks: percentage}
                }
            }
            return {color: "red", percentage_checks: 0}
        }
    }
}