// frontend\src\modules\authentication\authStore.js
import axiosInstance from '@/plugins/connectionBuilder.js'
import router from '@/router';

const authStore = {
    state: {
        isUserLoggedIn: false,
        isUserAdmin: false,
        isUserAdminOrRegul: false,
        isResponsablePistes: false,
        isResponsablePistesOrRegul: false,
        isResponsableRMOrRegul: false,
        isResponsableRM: false,
        isRM: false,
        isPistes: false,
        isProcessing: false,
        isProcessingInfosModif: false,
        isProcessingPwdModif: false,
        isProcessingAvatarModif: false,
        loginToken: '',
        dataUser: {},
        listAccounts: [],
        listPatrollers: [],
        tokenFirebaseIsUpdated: false,
        deferredPrompt: null,
        listNotifsAccountsCreated: [],
        rolesAvailable: [],
    },
    mutations: {
        setRolesAvailable(state, autorisationsRaw) {
            state.rolesAvailable = autorisationsRaw.map(el => ({text: el.name_description, value: el.name}))
        },
        setLogged(state, payload) {
            let rememberMe = false
            if ("rememberMe" in payload){
                rememberMe = payload.rememberMe
            }
            let loginResult = payload.loginResult
            state.isUserLoggedIn = loginResult.success;
            if (loginResult.success) {
                const groups_of_roles = {
                    'admin': ['responsable_pistes', 'responsable_rm'],
                    'rm': ['conducteur_rm', 'vigie_rm', 'controleur_rm', 'responsable_rm', 'mecanicien', 'chef_equipe_rm', 'strmtg'],
                    'pistes': ['responsable_pistes', 'pisteur']
                }
                state.loginToken = loginResult.token;
                state.isUserAdmin = loginResult.dataUser.autorisation.some(el => groups_of_roles['admin'].includes(el))
                state.isUserAdminOrRegul = loginResult.dataUser.autorisation.some(el => groups_of_roles['admin'].includes(el) || el == 'regulateur')
                state.isResponsablePistes = loginResult.dataUser.autorisation.some(el => el == 'responsable_pistes')
                state.isResponsablePistesOrRegul = loginResult.dataUser.autorisation.some(el => el == 'responsable_pistes' || el == 'regulateur')
                state.isResponsableRMOrRegul = loginResult.dataUser.autorisation.some(el => el == 'responsable_rm' || el == 'regulateur')
                state.isResponsableRM = loginResult.dataUser.autorisation.some(el => el == 'responsable_rm')
                state.isRM = loginResult.dataUser.autorisation.some(el => groups_of_roles['rm'].includes(el) || el == 'regulateur')
                state.isPistes = loginResult.dataUser.autorisation.some(el => groups_of_roles['pistes'].includes(el) || el == 'regulateur')
                state.dataUser = loginResult.dataUser;
                if (rememberMe) {
                    localStorage.loginToken = state.loginToken
                }
            } else {
                localStorage.removeItem("loginToken");
            }
        },
        setIsProcessing(state, isProcessing) {
            state.isProcessing = isProcessing
        },
        setIsProcessingPwdModif(state, isProcessingPwdModif) {
            state.isProcessingPwdModif = isProcessingPwdModif
        },
        setIsProcessingInfosModif(state, isProcessingInfosModif) {
            state.isProcessingInfosModif = isProcessingInfosModif
        },
        setIsProcessingAvatarModif(state, isProcessingAvatarModif) {
            state.isProcessingAvatarModif = isProcessingAvatarModif
        },
        setLogout(state) {
            localStorage.removeItem('loginToken');
            state.dataUser = {}
            state.isUserLoggedIn = false;
            state.isUserAdmin = false;
            state.isUserAdminOrRegul = false;
            state.loginToken = '';
            state.isResponsablePistes = false
            state.isResponsablePistesOrRegul = false
            state.isResponsableRMOrRegul = false
            state.isResponsableRM = false
            state.isRM = false
            state.isPistes = false
        },
        setListAccounts(state, listAccounts){
            state.listAccounts = listAccounts;
        },
        setPatrollers(state, listPatrollers){
            state.listPatrollers = listPatrollers;
        },
        setUpdateTokenFirebaseStatus(state, result) {
            state.tokenFirebaseIsUpdated = result;
        },
        addListNotifsAccountsCreated(state, msg) {
            state.listNotifsAccountsCreated.push(msg)
        },
    },
    actions: {
        getRoles({ commit, dispatch }) {
            commit('setIsProcessing', true);
            return axiosInstance.post('getRoles')
                .then(function (response) {
                    commit('setIsProcessing', false);
                    commit('setRolesAvailable', response.data.message)
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        registerNewUser({ commit, dispatch }, payload) {
            commit('setIsProcessing', true);
            return axiosInstance.post('register', payload)
                .then(function (response) {
                    commit('setIsProcessing', false);
                    commit('addListNotifsAccountsCreated', response.data.dataTable)
                    dispatch("addNotification", {message: response.data.message, type: "success"});
                    dispatch("updateListAccounts");
                })
                .catch(function (error) {
                    commit('setIsProcessing', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        authenticateUserAndSetToken({ state, commit, dispatch }, payload) {
            payload = payload ? payload : {}
            commit('setIsProcessing', true);
            axiosInstance.post('token', payload)
            .then(function (response) {
                commit('setIsProcessing', false);
                const rememberMe = "rememberMe" in payload ? payload.rememberMe : true
                const data = response.data
                commit('setLogged', {'loginResult': data, 'rememberMe': rememberMe});
                if (response.data.success) {
                    if (response.data.isFirstConnexion) {
                        router.push('/accountDetails/me')
                        dispatch("addNotification", {message: "Vous vous connectez pour la 1ère fois, veuillez modifier votre mot de passe", type: "success"});
                    } else {
                        if(state.isPistes){
                            router.push('/dataSlopes')
                        } else if(state.isResponsableRM){
                            router.push('/liftCentral')
                        }else if(state.isRM){
                            router.push('/liftRecord')
                        } else {
                            router.push('/accountDetails/me')
                        }
                    }
                    axiosInstance.defaults.headers.common['Authorization'] = response.data.token;
                    if(localStorage.firebaseToken){
                        dispatch("updateFirebaseTokenUser", {"userId": state.dataUser["userId"], 'tokenFirebase': localStorage.firebaseToken});
                    }
                } else {
                    dispatch("addNotification", {message: response.data.message, type: "error"});
                }
            })
            .catch(function (error) {
                commit('setIsProcessing', false);
                if (error.response && error.response.data.message) {
                    commit('setLogged', {'loginResult': { success: false }});
                    dispatch("addNotification", {message: error.response.data.message, type: "error"});
                } else {
                    commit('setLogged', {'loginResult': { success: false }});
                    dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                }
            });
        },
        logout({ commit }, payload) {
            return new Promise(function (resolve, reject) {
                let controllerReference = payload.controllerReference;
                delete (payload.controllerReference);
                axiosInstance.post('logout', payload)
                    .then(function () {
                        commit('setIsProcessing', false);
                        commit('setLogout');
                        resolve(controllerReference);
                    })
                    .catch(function (error) {
                        commit('setIsProcessing', false);
                        if (error.response && error.response.data.message) {
                            commit('setLogout');
                        } else {
                            commit('setLogout');
                        }
                        reject();
                    });
            });
        },
        getAccount({dispatch}, payload){
            return new Promise(function (resolve, reject) {
                axiosInstance.post('accounts/getAccounts/' + payload['accountId'])
                    .then(function (response) {
                        const result = {data: response.data.message, controller: payload['controllerReference']}
                        resolve(result)
                    })
                    .catch(function (error) {
                        if (error.response && error.response.data.message) {
                            dispatch("addNotification", {message: error.response.data.message, type: "error"});
                        } else {
                            dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                        }
                        reject()
                    });
            })
        },
        updateListPatrollers({commit, dispatch}){
            axiosInstance.post('listPatrollers')
                .then(function (response) {
                    commit('setPatrollers', response.data.message);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        updateListAccounts({commit, dispatch}){
            axiosInstance.post('accounts/getAccounts/all')
                .then(function (response) {
                    commit('setListAccounts', response.data.message);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        updateFirebaseTokenUser({commit}, payload){
            axiosInstance.post('updateTokenFirebase', payload)
                .then(function (response) {
                    commit('setUpdateTokenFirebaseStatus', response.status);
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        commit('setUpdateTokenFirebaseStatus', error.response.data.message);
                    } else {
                        commit('setUpdateTokenFirebaseStatus', "Erreur interne de serveur");
                    }
                });
        },
        deleteAccount({dispatch}, payload){
            axiosInstance.post('accounts/deleteAccount', {'accountId' : payload.accountId})
                .then(function (response) {
                    dispatch('updateListAccounts');
                    dispatch("addNotification", {message: response.data.message, type: "success"});
                })
                .catch(function (error) {
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur interne de serveur", type: "error"});
                    }
                });
        },
        modifyInformations({ state, commit, dispatch }, payload) {
            commit('setIsProcessingInfosModif', true);
            axiosInstance.post('accounts/modifyInfosAccount', payload)
                .then(function (response) {
                    commit('setIsProcessingInfosModif', false);
                    if(payload.userId == state.dataUser['userId']){
                        state.dataUser = response.data.dataUser;
                    }
                    dispatch("addNotification", {message: "Informations modifiées avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessingInfosModif', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur lors de la modification, veuillez réessayer", type: "error"});
                    }
                });
        },
        modifyPassword({ commit, dispatch }, payload) {
            commit('setIsProcessingPwdModif', true);
            axiosInstance.post('accounts/modifyPwdAccount', payload)
                .then(function () {
                    commit('setIsProcessingPwdModif', false);
                    dispatch("addNotification", {message: "Mot de passe modifié avec succès", type: "success"});
                })
                .catch(function (error) {
                    commit('setIsProcessingPwdModif', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur lors de la modification, veuillez réessayer", type: "error"});
                    }
                });
        },
        generateNewPassword({ commit, dispatch }, payload) {
            commit('setIsProcessingPwdModif', true);
            return new Promise(function (resolve, reject) {
                axiosInstance.post('accounts/generatePwdAccount', {'userId': payload['userId']})
                .then(function (response) {
                    commit('setIsProcessingPwdModif', false);
                    dispatch("addNotification", {message: response.data.message , type: "success", time: 6});
                    const result = {data: response.data.dataTable, controller: payload['controllerReference']}
                    resolve(result)
                })
                .catch(function (error) {
                    commit('setIsProcessingPwdModif', false);
                    if (error.response && error.response.data.message) {
                        dispatch("addNotification", {message: error.response.data.message, type: "error"});
                    } else {
                        dispatch("addNotification", {message: "Erreur lors de la modification, veuillez réessayer", type: "error"});
                    }
                    reject()
                });
            })
        },
        modifyAvatar({ state, commit, dispatch }, payload) {
            commit('setIsProcessingAvatarModif', true);
            return new Promise(function (resolve, reject) {
                axiosInstance.post('accounts/modifyAvatarAccount', payload)
                    .then(function () {
                        commit('setIsProcessingAvatarModif', false);
                        if(payload.avatarAction == 'add'){
                            dispatch("addNotification", {message: "Avatar modifié avec succès", type: "success"});
                            if(payload.userId == state.dataUser['userId']){
                                state.dataUser["pathAvatar"] = payload.avatar;
                            }
                        } else if(payload.avatarAction == 'delete'){
                            dispatch("addNotification", {message: "Avatar supprimé avec succès", type: "success"});
                            if(payload.userId == state.dataUser['userId']){
                                state.dataUser["pathAvatar"] = 'None';
                            }
                        }
                        resolve()
                    })
                    .catch(function (error) {
                        commit('setIsProcessingAvatarModif', false);
                        if (error.response && error.response.data.message) {
                            dispatch("addNotification", {message: error.response.data.message, type: "error"});
                        } else {
                            dispatch("addNotification", {message: "Erreur lors de la modification, veuillez réessayer", type: "error"});
                        }
                        reject()
                    });
            })
        },
        readForfait({dispatch}){
            return new Promise(function (resolve, reject) {
                if('NDEFReader' in window){
                    /*global NDEFReader*/
                    const ndef = new NDEFReader();
                    const controller = new AbortController();
                    ndef.scan({signal: controller.signal}).then(() => {
                    ndef.onreadingerror = () => {
                        dispatch("addNotification", {message: "Impossible de lire ce forfait, veuillez réessayer", type: "error"});
                        controller.abort();
                        reject()
                    };
                    ndef.onreading = event => {
                        controller.abort();
                        resolve(event.serialNumber)
                    };
                    }).catch(error => {
                    dispatch("addNotification", {message: "Impossible de lire ce forfait : " + error, type: "error"});
                    controller.abort();
                    reject()
                    });
                } else {
                    dispatch("addNotification", {message: "Connexion par forfait impossible : vous n'avez pas de capteur NFC", type: "error"});
                    reject()
                }
            })
        },
        loginForfait({dispatch}, rememberMe){
            dispatch('readForfait').then((ski_pass_number) => dispatch('authenticateUserAndSetToken', {ski_pass_number: ski_pass_number, rememberMe: rememberMe}))
        }
    },
    getters: {
        isProcessing(state) {
            return state.isProcessing;
        },
        isProcessingInfosModif(state) {
            return state.isProcessingInfosModif;
        },
        isProcessingPwdModif(state) {
            return state.isProcessingPwdModif;
        },
        isProcessingAvatarModif(state) {
            return state.isProcessingAvatarModif;
        },
        listNotifsAccountsCreated(state) {
            return state.listNotifsAccountsCreated
        },
    }
}

export default authStore;