<!-- 
frontend\src\modules\authentication\Register.vue
-->
<template>
  <v-card>
    <v-card-title primary-title>
      <h1>S'inscrire</h1>
    </v-card-title>
    <v-card-text>
      <v-form rounded v-model="isRegisterFormValid">
        <v-text-field
          name="email"
          label="Nom d'utilisateur/Email*"
          id="email"
          v-model="email"
          prepend-icon="mdi-account-circle"
          required
          :rules="[checkIsRequired(email), checkMinLenght(email.length,8)]"
          type="email"
        ></v-text-field>

        <v-text-field
          name="password"
          label="Mot de passe*"
          id="password"
          v-model="password"
          :type="getPasswordFieldType()"
          prepend-icon="mdi-lock"
          :append-icon="getShowPasswordApendIcon()"
          @click:append="toggleShowPassword()"
          counter="50"
          required
          :rules="[checkIsRequired(password), checkMinLenght(password.length,8)]"
          loading
        >
          <template v-slot:progress>
            <v-progress-linear :value="progress(8)" :color="color(8)" absolute height="7"></v-progress-linear>
          </template>
        </v-text-field>

        <v-text-field
          name="firstname"
          label="Prénom*"
          id="firstname"
          v-model="firstname"
          :rules="[checkIsRequired(firstname)]"
        ></v-text-field>

        <v-text-field
          name="surname"
          label="Nom de famille*"
          id="surname"
          v-model="surname"
          :rules="[checkIsRequired(surname)]"
        ></v-text-field>

        <v-text-field
          name="token"
          label="Token de sécurité*"
          id="token"
          v-model="token"
          :rules="[v => v == 'pisteurcorbiersja' || 'Le token n\'est pas bon']"
        ></v-text-field>

        <div class="text-center">
          <v-progress-circular indeterminate color="primary" v-if="isProcessing"></v-progress-circular>
        </div>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <router-link to="login">Se connecter</router-link>
      <v-spacer></v-spacer>
      <v-btn
        outlined
        elevation="9"
        :disabled="!isRegisterFormValid || isProcessing"
        @click="registerNewUser({email:email, password:password, firstname:firstname, surname:surname, autorisation: ['pisteur']})"
      >
        <v-icon left>mdi-account-plus</v-icon>S'inscrire
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Register",
  data: function() {
    return {
      showPassword: false,
      isRegisterFormValid: false,
      email: "",
      password: "",
      firstname: "",
      surname: ""
    };
  },
  methods: {
    getPasswordFieldType: function() {
      if (this.showPassword) {
        return "text";
      } else {
        return "password";
      }
    },
    toggleShowPassword: function() {
      this.showPassword = !this.showPassword;
    },
    getShowPasswordApendIcon: function() {
      if (this.showPassword) {
        return "mdi-eye";
      } else {
        return "mdi-eye-off";
      }
    },
    checkIsRequired: function(value, errorMessage) {
      if (!errorMessage) {
        errorMessage = "Ce champ est nécessaire";
      }
      return !!value || errorMessage;
    },
    checkMinLenght(valueLenght, minLength, errorMessage) {
      if (!errorMessage) {
        errorMessage = `La longueur minimale est ${minLength}`;
      }
      return (!!valueLenght && valueLenght >= minLength) || errorMessage;
    },
    progress(minLength) {
      return Math.min(100, (this.password.length / minLength) * 100);
    },
    color(minLength) {
      let selectedColorIndex = 0;
      if (this.progress(minLength) < 40) {
        selectedColorIndex = 0;
      } else if (
        this.progress(minLength) > 40 &&
        this.progress(minLength) < 100
      ) {
        selectedColorIndex = 1;
      } else {
        selectedColorIndex = 2;
      }
      return ["error", "warning", "success"][selectedColorIndex];
    },
    ...mapActions(["registerNewUser"]),
    validateEmail(email) {
      var re = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      return (
        re.test(String(email).toLowerCase()) ||
        "Oops, this doesn't looks like rigth, can you check please?"
      );
    }
  },
  computed: {
    ...mapGetters([
      "isProcessing"
    ])
  }
};
</script>