<template>
    <v-dialog
    v-model="dialogDescription"
    max-width="500px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                large
                v-bind="attrs"
                v-on="on"
            >
                <v-icon >
                    mdi-information-outline
                </v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="text-h5">Informations tâches</span>
                <v-spacer></v-spacer>
                <v-btn icon @click="dialogDescription = false" >
                <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>

            <v-card-text>
            {{ task.description }}
            </v-card-text>
            <v-card-text>
            {{ task.date_usage }}
            </v-card-text>
            <v-card-text>
            {{ task.firstname }} {{ task.surname }}
            </v-card-text>
            <v-card-text>
            {{ task.time_creation }}
            </v-card-text>
            
            <v-card-text>
                <v-img width="80%" class="mx-auto" v-if="task.image" :src="task.image" alt=""></v-img>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="blue darken-1"
                text
                @click="dialogDescription = false"
                >
                Fermer
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "DialogInfosTask",
    props: {
        task: Object
    },
    data() {
        return {
            dialogDescription: false,
        }
    }
}
</script>