<template>
  <!-- Drawer -->
  <v-navigation-drawer app permanent mini-variant expand-on-hover dark color="#194f73">
    <v-btn absolute right :text="true" v-show="isVisible" :disabled="true" height="48px" class="titlePage" style="color: rgb(25, 79, 115) !important;">
      {{ pageTitle }}
    </v-btn>
    <v-list>
      <v-list-item>
        <v-list-item-avatar :tile="true">
          <img class="mr-3" src="@/assets/digiski_blanc_50_50.png" height="50" alt=""/>
        </v-list-item-avatar>
        <v-list-item-title class="text-h6">Digiski</v-list-item-title>
      </v-list-item>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="text-h8">
            {{ messageConnected }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <v-list nav dense>
      <div v-for="(group, index) in nav" :key="index">
        <!--
        <v-list-group v-if="group.groupTitle" :value="true">
          <template v-slot:activator>
            <v-list-item>
              <v-divider v-if="index != 0"></v-divider>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>{{ group.groupIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ group.groupTitle }}</v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item v-for="item in group.linksFiltered" :key="item.url" :to="item.url">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-list-item v-else v-for="item in group.linksFiltered" :key="item.url" :to="item.url">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
        -->
        <v-list-item v-if="group.groupTitle">
          <v-divider></v-divider>
          <v-subheader class="mx-2">{{ group.groupTitle }}</v-subheader>
          <v-divider></v-divider>
        </v-list-item>
        <v-list-item v-for="item in group.linksFiltered" :key="item.url" :to="item.url">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import debounce from "lodash.debounce";
export default {
  data: function() {
    return {
      isVisible: true
    };
  },
  props: {
    nav: Array,
    messageConnected: String,
    pageTitle: String,
  },
  methods: {
    scrollHandler(e) {
      this.isVisible = !(e.target.lastChild.scrollTop > 20)
    }
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.scrollHandler, 100);
    window.addEventListener('scroll', this.handleDebouncedScroll);
  },
  beforeDestroy() {
    // I switched the example from `destroyed` to `beforeDestroy`
    // to exercise your mind a bit. This lifecycle method works too.
    window.removeEventListener('scroll', this.handleDebouncedScroll);
  }
}
</script>

<style scoped>
  .v-navigation-drawer--mini-variant, .v-navigation-drawer {
    overflow: visible !important;
  }

  .titlePage {
    transform: translate(105%);
    text-transform: inherit;
    font-size: 1.2em;
    font-weight: bold;
  }
</style>