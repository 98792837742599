import Vue from 'vue'
import VueRouter from 'vue-router'
import RegisterView from '../views/RegisterView.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  { path: '/',
    redirect: '/login'},
  {
    path: '/register',
    name: "S'inscrire",
    component: RegisterView,
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
    }
  },
  {
    path: '/login',
    name: "Se connecter",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue'),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: '/dataSlopes',
    name: "Registre des pistes",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OpenSlopes.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dataDownloads',
    name: 'Historique',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Historic.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/map',
    name: 'Carte globale',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/MapLifts.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/liftRecord',
    name: 'Registre RM',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LiftRecord.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/testsConfiguration',
    name: 'Configuration essais RM',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/TestsConfiguration.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/liftCentral',
    name: 'Central RMs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LiftsGlobalDashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/manageAccounts',
    name: 'Gestion des comptes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ManageAccounts.vue'),
    meta: {
      requiresAdminOrRegul: true,
      requiresAuth: true
    }
  },
  {
    path: '/accountDetails/:id',
    name: 'Détails du compte',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AccountDetails.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    name: '404 - Not Found',
    component: () => import(/* webpackChunkName: "about" */ '../views/404NotFound.vue'),
    meta: {
      requiresAuth: false
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.state.authStore.isUserLoggedIn) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
  if (to.matched.some(record => record.meta.requiresAdminOrRegul)) {
    // this route requires admin, check if logged in
    // if not, redirect to login page.
    if (!store.state.authStore.isUserAdminOrRegul) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require admin, make sure to always call next()!
  }
  if (to.matched.some(record => record.meta.requiresResponsableRMOrRegul)) {
    // this route requires admin, check if logged in
    // if not, redirect to login page.
    if (!store.state.authStore.isResponsableRMOrRegul) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require admin, make sure to always call next()!
  }
  if (to.matched.some(record => record.meta.requiresRM)) {
    // this route requires admin, check if logged in
    // if not, redirect to login page.
    if (!store.state.authStore.isRM) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require admin, make sure to always call next()!
  }
  if (to.matched.some(record => record.meta.requiresPistes)) {
    // this route requires admin, check if logged in
    // if not, redirect to login page.
    if (!store.state.authStore.isPistes) {
      next({ path: '/login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require admin, make sure to always call next()!
  }
})

export default router
