import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import './registerServiceWorker'
import firebaseMessaging from './firebase'
import JsonCSV from 'vue-json-csv'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.config.productionTip = false

Vue.prototype.$messaging = firebaseMessaging

//Create notification with : this.$notification.add({message: "test1", type: "success", time (optional): 30}); (or dispatch in stores)
Vue.prototype.$notification = {
  add(notif){
    store.dispatch("addNotification", notif);
  }
}

Vue.component('downloadCsv', JsonCSV)

Vue.use(DatetimePicker)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')