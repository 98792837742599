<template>
    <div id="notificationCenter">
      <alert-notification v-for="(item, index) in this.$store.state.notifStore.notifications" :message="item.message" :type="item.type" :time="item.time" :key="index">
      </alert-notification>
    </div>
</template>

<script>
    import AlertNotification from "./AlertNotification.vue";
    export default {
        name: "NotificationsCenter",
        components: { AlertNotification }
    }
</script>