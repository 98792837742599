export default {
    state: {
        notifications: []
    },
    mutations: {
        addNotification(state, notification){
            state.notifications.push(notification);
        },
        removeNotificationByIndex(state, indexNotification){
            state.notifications.splice(indexNotification, 1);
        }
    },
    actions: {
        addNotification({commit}, notification){
            commit("addNotification", notification);
        },
        removeNotification({commit}, indexNotification){
            commit("removeNotificationByIndex", indexNotification);
        }
    }

}